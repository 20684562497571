import { Component, OnInit, Input, Output, EventEmitter, DebugElement } from '@angular/core';
import { FormControlName, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-generic-typeahead',
  templateUrl: './generic-typeahead.component.html',
  styleUrls: ['./generic-typeahead.component.css']
})
export class GenericTypeaheadComponent implements OnInit {

  @Input()
  parentFormGroup: FormGroup;

  @Input()
  controlName ?: string;

  @Input()
  items: Array<string>;

  @Input()
  labelCaption ?= '';

  @Input()
  placeholder ?= 'Please enter a search term';

  @Output()
  itemSelected: EventEmitter<string>;

  model: any;

  constructor() {
    this.itemSelected = new EventEmitter<string>();
  }

  ngOnInit(): void {}

  selectItem(item: string) {
    this.itemSelected.emit(item);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.items.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )
}
