// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  client: 'royal',
  production: false,
  stripePublishableKey: 'pk_test_AKFzXNrTAU4SAL1NTUimhbl3',
  paypalClientId: 'ASWolWWmcWqVsTLFo4wdwlURxKGrXJYsWlWXoPzNzkza6apKHo_MkCb65jKoUpWornyGqqGUZTog2g7A',
  siteUrl: 'http://dev-royal.co2analytics.com/',
  defaultLanguage: 'en',
  minimumBaseCurrencyAmount: 0.75,
  hasShareControl: false,
  hideIntroFooterCaption: true,
  termsAndConditionsLink: 'https://www.climatecare.org/royal-mail-carbon-calculator-terms-conditions',
  hasBasketHeaderCaption: true,
  logoImg: {
    name: 'royal-mail-logo-sm.png',
    height: '122px'
  },
  footerLogoClass: 'ncp-rev',
  txOrigins: [
    '*'
  ],
  rxOrigins: [
    '*'
  ],
  partnerConfig: {
    partnerKey: '7d0b9a61-605b-465a-b350-006a154b428d',
    domain: 'climatecare.org'
  },
  apis: {
    domain: 'https://dev-srvc-calculators.co2analytics.com',
    fx: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    fin: 'https://dev-srvc-ecomm.co2analytics.com/api/v1',
    version: 'v1.0.0'
  },
  componentHierarchy: {
    headerComponents: [
      { templateName: 'calculatorHeaderComponent', componentName: 'CalculatorHeaderComponent' }
    ],
    featureComponents: [
      {
        name: 'intro',
        seqn: '0',
        title: 'Click Here to select a category to begin calculating your carbon emissions',
        theme: {
          fillColor: '#c6242f',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'introComponent', componentName: 'IntroComponent' }
      },
      {
        name: 'business-mail',
        seqn: '1',
        title: 'Business Mail',
        theme: {
          fillColor: '#cd3b3c',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'businessMailComponent', componentName: 'BusinessMailComponent' }
      },
      {
        name: 'direct-mail',
        seqn: '2',
        title: 'Direct Mail',
        theme: {
          fillColor: '#da5d57',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'directMailComponent', componentName: 'DirectMailComponent' }
      },
      {
        name: 'partially-addressed-mail',
        seqn: '3',
        title: 'Partially Addressed Mail',
        theme: {
          fillColor: '#e06d65',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'partiallyAddressedMailComponent', componentName: 'PartiallyAddressedMailComponent' }
      },
      {
        name: 'door-drops',
        seqn: '4',
        title: 'Door To Door',
        theme: {
          fillColor: '#d44d49',
          textClass: 'calculator-selector-text-light'
        },
        component: { templateName: 'doorDropsComponent', componentName: 'DoorDropsComponent' }
      }
    ],
    global: [
      {
        component: { defaultFeatureIndex: 0 }
      },
      {
        component: { templateName: 'offsetProjectsComponent', componentName: 'OffsetProjectsComponent' }
      },
      {
        component: { templateName: 'cartComponent', componentName: 'CartComponent' }
      },
      {
        component: { templateName: 'customerDetailsComponent', componentName: 'CustomerDetailsComponent' }
      },
      {
        component: { templateName: 'payOffsetsComponent', componentName: 'PayOffsetsComponent' }
      },
      {
        component: { templateName: 'confirmationComponent', componentName: 'ConfirmationComponent' }
      }
    ],
    footerComponents: [
      { templateName: 'icroaFooterComponent', componentName: 'IcroaFooterComponent' }
    ]
  }
}

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
