import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-offset-input',
  templateUrl: './offset-input.component.html',
  styleUrls: ['./offset-input.component.css']
})
export class OffsetInputComponent implements OnInit {

  @Input()
  primaryInput ? = 0;

  @Input()
  primaryLabel ? = '';

  @Input()
  primaryPlaceholder ? = '';

  @Input()
  primaryOptions ?: Array<string>;

  @Input()
  primaryInputPlaceholder ? = '';

  @Input()
  secondaryOptions?: Array<string>;

  @Input()
  secondaryOptionLabel ? = '';

  @Input()
  secondaryInputPlaceholder ? = '';

  @Input()
  primaryOptionsDefault ? = '';

  @Input()
  secondaryOptionsDefault ? = '';

  offsetInputForm: FormGroup;

  @Output()
  primaryInputChanged: EventEmitter<number>;

  @Output()
  primaryOptionChanged: EventEmitter<string>;

  @Output()
  secondaryOptionChanged: EventEmitter<string>;

  constructor(private formBuilder: FormBuilder) {
    this.primaryInputChanged = new EventEmitter<number>();
    this.primaryOptionChanged = new EventEmitter<string>();
    this.secondaryOptionChanged = new EventEmitter<string>();
  }

  ngOnInit(): void {
    this.offsetInputForm = this.formBuilder.group(
      {
        primaryInput: [this.primaryInput, Validators.required],
        primaryOption: [this.primaryOptionsDefault, Validators.required],
        secondaryOption: [this.secondaryOptionsDefault, Validators.required]
      }
    );
  }

  reset() {
    this.offsetInputForm.patchValue({
      primaryInput: 0,
      primaryOption: this.resetPrimaryOption(),
      secondaryOption: this.resetSecondaryOption()
    });
  }

  changePrimaryInput() {
    const primaryInput = this.offsetInputForm.get('primaryInput').value;

    if (primaryInput && primaryInput >= 0) {
      this.primaryInputChanged.emit(this.offsetInputForm.get('primaryInput').value);
    } else {
      this.offsetInputForm.controls['primaryInput'].setValue(0);
    }
  }

  selectPrimaryOption(value: string) {
    this.primaryOptionChanged.emit(value);
  }

  selectSecondaryOption(value: string) {
    this.secondaryOptionChanged.emit(value);
  }

  private resetPrimaryOption() {
    if (!this.primaryOptions || !this.primaryOptions.length) {
      return '';
    }
    return this.primaryOptions[0];
  }

  private resetSecondaryOption() {
    if (!this.secondaryOptions || !this.secondaryOptions.length) {
      return '';
    }
    return this.secondaryOptions[0];
  }
}
