import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent implements OnInit {

  @Input()
  statusCaption ?: string;

  @Input()
  spinnerContext ?: object;

  @Input()
  currentItems: number;

  @Input()
  expectedItems: number;

  constructor() { }

  ngOnInit() { }

}
